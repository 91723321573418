.home-banner {
  background-image: url("../../assets/images/HomepageBanner.png");
  background-size: cover;
  background-position: center;
  /* padding-bottom: rem; */
  width: 100%;
  height: auto;
  /* min-height: 800px; */
}
.responsive-iframe {
  aspect-ratio: 16/9;
}
.compass-img {
  display: block;
  width: 190px;
  margin: auto;
  min-width: 100px;
}
.home-title {
  font-family: "Fugi";
  color: white;
  font-size: 80px;
  width: 900px;
  text-align: center;
  margin: auto;
}
.round-btn {
  display: block;
  height: 60px;
  width: 280px;
  border-radius: 44px;
  margin-top: 90px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  font-size: 20px;
}
.home-linear-background {
  background: linear-gradient(180deg, #0c4369 0%, #041e42 72.22%);
  min-height: 1000px;
}
.story-image-container {
  position: relative;
  width: 900px;
  margin: auto;
  margin-bottom: 40px;
}
.story-big-image {
  display: block;
  margin: auto;
  width: 700px;
}
.story-small-image {
  position: absolute;
  width: 320px;
  top: 25%;
  left: 70%;
}
.story-title {
  font-family: Fugi;
  font-size: 70px;
  color: #fff;
  margin-top: -60px;
}
.story-title-quentin {
  font-family: Quentin;
  font-size: 80px;
  color: #ecbaa8;
  margin-top: -60px;
  padding-left: 40px;
}
.story-description {
  font-size: 30px;
  color: #fff;
  width: 900px;
  margin: auto;
  text-align: center;
  line-height: 45px;
}
.highlight-gallery-container {
  position: relative;
}
.highlight-gallery-content-container {
  width: 90%;
  max-width: 900px;
  margin: auto;
  margin-top: 100px;
  display: flex;
  z-index: 2;
  padding-bottom: 200px;
}
.highlight-gallery-image-container {
  background: #ffffff;
  box-shadow: 20px 19px 13px rgba(0, 0, 0, 0.2);
  transform: rotate(-3.53deg);
  width: 480px;
  z-index: 2;
  position: relative;
}
.highlight-gallery-img {
  width: 480px;
  padding: 4% 4% 20% 4%;
  object-fit: cover;
  z-index: 2;
}
.highlight-gallery-compass {
  position: absolute;
  bottom: -15%;
  right: -30%;
  z-index: 3;
  width: 300px;
}
.gallery-img-content {
  padding-left: 70px;
  color: #fff;
  z-index: 2;
}
.gallery-img-content h1 {
  font-family: Fugi;
  font-size: 50px;
  line-height: 65px;
  /* font-size: 5vw; */
  /* line-height: 6vw; */
  font-weight: 400;
  z-index: 2;
}

.gallery-title-quentin {
  font-family: Quentin;
  color: #cf9d8e;
  font-size: 50px;
  line-height: 50px;
}
.gallery-img-content p {
  margin-top: 30px;
  font-weight: 300;
  font-size: 22px;
  line-height: 35px;
  z-index: 2;
}
.paperBG {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  max-height: 450px;
}
.news-activity-content {
  width: 90%;
  max-width: 900px;
  margin: auto;
  margin-top: 75px;
  margin-bottom: 75px;
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  display: block;
  text-align: center;
  color: #fff;
}
.news-activity-title {
  /* width: 40%; */
  width: auto;
  font-family: Fugi;
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
}
.news-activity-title a {
  color: white;
  text-decoration: none;
}
.news-activity-title a:hover {
  color: white;
}
.news-activity-title h1 {
  font-size: 110px;
  line-height: 110px;
  text-align: left;
}
.news-activity-title h2 {
  font-size: 200px;
  /* font-family: Quentin; */
  /* font-size: 110px; */
  color: #ecbaa8;
  position: absolute;
  z-index: 99;
  top: -20%;
  right: 24%;
  /* top: 35%; */
  /* left: 50%; */
  opacity: 0.9;
}
.news-activity-description {
  /* width: 40%; */
  margin-top: 50px;
  font-weight: 300;
  font-size: 35px;
  line-height: 50px;
}
.news-activity-carousel img {
  height: 600px;
  object-fit: cover;
  object-position: center;
}
.japan-travel-container {
  width: 90%;
  max-width: 900px;
  display: flex;
  margin: auto;
  margin-top: 100px;
  padding-bottom: 100px;
  position: relative;
}
.japan-travel-content-container {
  width: 50%;
  margin-right: 100px;
}
.japan-travel-content-container h1 {
  font-family: Fugi;
  font-weight: 400;
  font-size: 100px;
  line-height: 140px;
  color: #fff;
}
.japan-travel-content-container h2 {
  position: absolute;
  top: 25%;
  left: 20%;
  font-family: "Quentin";
  font-style: normal;
  font-weight: 400;
  font-size: 170px;
  line-height: 210px;
  background: linear-gradient(270deg, #cf9d8e 10.54%, #eacabb 96.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.japan-travel-img {
  width: 100%;
}
.japan-travel-img img {
  width: 100%;
}
.package-tour {
  width: 90%;
  max-width: 900px;
  margin: auto;
  margin-bottom: 100px;
}
.package-tour img {
  width: 100%;
  max-width: 900px;
  border: 15px solid white;
}

.package-tour-btn {
  display: flex;
  justify-content: space-around;
}
.package-tour-btn a {
  align-items: center;
  padding: 10px 32px;
  border-radius: 48px;
  margin-top: 30px;
  font-size: 30px;
  border: 1px solid transparent;
  background-color: #bc8a7e;
  color: white;
  text-decoration: none;
  width: 280px;
  text-align: center;
}
.package-tour-btn button {
  align-items: center;
  padding: 10px 32px;
  border-radius: 48px;
  margin-top: 30px;
  font-size: 1.6vw;
  border: 1px solid transparent;
  background-color: #777;
  color: white;
  text-decoration: none;
}
.package-tour-btn a:hover {
  color: #fff;
}
.japan-travel-content-container button {
  align-items: center;
  padding: 10px 32px;
  border-radius: 48px;
  margin-top: 130px;
  font-size: 30px;
  border: 1px solid transparent;
  background-color: #bc8a7e;
  color: white;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .compass-img {
    width: 80px;
  }
  .home-title {
    font-size: 32px;
    width: 100%;
    margin-bottom: 0px;
  }
  .round-btn {
    height: 30px;
    width: 140px;
    border-radius: 22px;
    margin-top: 15px;
    font-size: 10px;
  }
  .story-image-container {
    width: 90%;
    margin-bottom: 20px;
  }
  .story-big-image {
    display: block;
    /* margin-left: auto; */
    margin-right: auto;
    width: 80%;
  }
  .story-small-image {
    width: 30%;
  }
  .story-title {
    font-size: 7vw;
    margin-top: -7%;
  }
  .story-title-quentin {
    font-size: 8vw;
    margin-top: -7%;
    padding-left: 20px;
  }
  .story-description {
    font-size: 2.9vw;
    line-height: 4.5vw;
    width: 90%;
  }
  .highlight-gallery-content-container {
    margin-top: 50px;
    padding-bottom: 100px;
  }
  .highlight-gallery-image-container {
    width: 50%;
    margin-top: 10%;
  }
  .highlight-gallery-img {
    width: 100%;
  }
  .highlight-gallery-compass {
    width: 25vw;
  }
  .gallery-img-content {
    width: 50%;
    padding-left: 5%;
  }
  .gallery-img-content h1 {
    font-size: 5vw;
    line-height: 6vw;
  }
  .gallery-title-quentin {
    font-size: 5vw;
    line-height: 7vw;
  }
  .gallery-img-content p {
    margin-top: 5%;
    font-size: 2.9vw;
    line-height: 4.5vw;
  }
  .paperBG {
    max-height: 225px;
  }
  .news-activity-content {
    margin-top: 37.5px;
    margin-bottom: 37.5px;
  }
  .news-activity-title h1 {
    font-size: 10vw;
    line-height: 10vw;
  }
  .news-activity-title h2 {
    font-size: 19vw;
  }
  .news-activity-description {
    margin-top: 3vw;
    font-size: 2.9vw;
    line-height: 4.5vw;
  }
  .news-activity-carousel img {
    height: 350px;
  }
  .japan-travel-container {
    width: 90%;
    margin-top: 50px;
    padding-bottom: 50px;
  }
  .japan-travel-content-container {
    width: 40%;
    margin-right: 3%;
  }
  .japan-travel-content-container h1 {
    font-size: 10vw;
    line-height: 12vw;
  }
  .japan-travel-content-container h2 {
    font-size: 12vw;
    line-height: 12vw;
  }
  .japan-travel-img {
    width: 60%;
  }
  .japan-travel-img img {
    width: 100%;
  }
  .package-tour-btn a {
    align-items: center;
    padding: 7px 18px;
    border-radius: 24px;
    font-size: 20px;
    width: 160px;
  }
  .package-tour-btn button {
    align-items: center;
    padding: 7px 18px;
    border-radius: 24px;
    font-size: 20px;
  }
  .japan-travel-content-container button {
    align-items: center;
    padding: 7px 18px;
    border-radius: 24px;
    font-size: 12px;
    margin-top: 60px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .compass-img {
    width: 150px;
  }
  .home-title {
    font-size: 60px;
    width: 700px;
    margin-bottom: 45px;
  }
  .round-btn {
    height: 55px;
    width: 240px;
    border-radius: 33px;
    margin-top: 20px;
    font-size: 17px;
  }
  .story-image-container {
    width: 90%;
    margin-bottom: 20px;
  }
  .story-big-image {
    display: block;
    /* margin-left: auto; */
    margin-right: auto;
    width: 80%;
  }
  .story-small-image {
    width: 30%;
  }
  .story-title {
    font-size: 7vw;
    margin-top: -7%;
  }
  .story-title-quentin {
    font-size: 8vw;
    margin-top: -7%;
    padding-left: 20px;
  }
  .story-description {
    font-size: 2.9vw;
    line-height: 4.5vw;
    width: 90%;
  }
  .highlight-gallery-content-container {
    margin-top: 50px;
    padding-bottom: 100px;
  }
  .highlight-gallery-image-container {
    width: 50%;
    margin-top: 10%;
  }
  .highlight-gallery-img {
    width: 100%;
  }
  .highlight-gallery-compass {
    width: 25vw;
  }
  .gallery-img-content {
    width: 50%;
    padding-left: 5%;
  }
  .gallery-img-content h1 {
    font-size: 5vw;
    line-height: 6vw;
  }
  .gallery-title-quentin {
    font-size: 5vw;
    line-height: 7vw;
  }
  .gallery-img-content p {
    margin-top: 5%;
    font-size: 2.9vw;
    line-height: 4.5vw;
  }
  .paperBG {
    max-height: 225px;
  }
  .news-activity-content {
    margin-top: 37.5px;
    margin-bottom: 37.5px;
  }
  .news-activity-title h1 {
    font-size: 10vw;
    line-height: 10vw;
  }
  .news-activity-title h2 {
    font-size: 19vw;
  }
  .news-activity-description {
    margin-top: 3vw;
    font-size: 2.9vw;
    line-height: 4.5vw;
  }
  .news-activity-carousel img {
    height: 350px;
  }
  .japan-travel-container {
    width: 90%;
    margin-top: 50px;
    padding-bottom: 50px;
  }
  .japan-travel-content-container {
    width: 40%;
    margin-right: 3%;
  }
  .japan-travel-content-container h1 {
    font-size: 10vw;
    line-height: 12vw;
  }
  .japan-travel-content-container h2 {
    font-size: 12vw;
    line-height: 12vw;
  }
  .japan-travel-img {
    width: 60%;
  }
  .japan-travel-img img {
    width: 100%;
  }
  .package-tour-btn a {
    align-items: center;
    padding: 7px 18px;
    border-radius: 24px;
    font-size: 20px;
    width: 200px;
  }
  .package-tour-btn button {
    align-items: center;
    padding: 7px 18px;
    border-radius: 24px;
    font-size: 20px;
  }
  .japan-travel-content-container button {
    align-items: center;
    padding: 7px 18px;
    border-radius: 24px;
    font-size: 20px;
    margin-top: 60px;
  }
}
